import React from 'react'
import './Header.scss';
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper'
import { images, sources } from '../../constants'

const scaleVariants = {
  whileInView: {
    scale: [0,1],
    opacity: [0, 1],
    transition: {
      duration: 1, 
      ease: 'easeInOut'
    }
  }
}

function Header() {
  return (
    <div className='app__header app__flex'>
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1]}}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        <div className='app__header-badge'>
          <div className='badge-cmp app__flex'>
            <span>👋</span>
            <div style={{ marginLeft: 20 }}>
                <p className='p-text'> Hello, I am</p>
                <h1 className='head-text'>Nahuel</h1>
            </div>
          </div>

          {/* <div className='tag-cmp app__flex'>
            <p className='p-text'> Telecom. Engineering student</p>
          </div> */}

          <div className='tag-cmp app__flex'>
            <p className='p-text'>Cybersecurity Analyst</p>
          </div>
          <div className='tag-cmp app__flex'>
            <p className='p-text'>Penetration tester</p>
          </div>
        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1]}}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__header-img"
      >
        <img src={images.profile} alt="profile_bg" />
        <div className='img-btns'>
          <a href="/ben1tz/links"  ><button type="button" className='p-text'>Portfolio</button></a>
          <a href={sources.resume} target="_blank" ><button type="button" className='p-text' href="" >Resume/CV</button></a>
        </div>    
      </motion.div>
      
      <motion.div
        variants={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className='app__header-circles'
      >
        {[images.ejpt, images.kali_logo, images.python].map((circle, index) => (
          <div className='circle-cmp app__flex' key={`circle-${index}`}>
            <img src={circle} alt="circle" />
          </div>
        ))}
      </motion.div>
    </div>
  )
}

export default AppWrap(Header, 'home')