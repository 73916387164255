import React from 'react'
import { About, Footer, Header, Skills, Work, Navbar } from './container';

function Home() {
  return (
      <div>
        <Navbar />
        <Header />
        <About />
        {/* <Work /> */}
        <Skills />
        <Footer />
      </div>
  )
}

export default Home;