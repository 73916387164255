import React, { useState } from 'react'
import './Footer.scss';

import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';

function Footer() {

  const [formData, setformData] = useState({name: "", email: "", message: ""})
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const {name, email, message} = formData;
  const handleChangeInput = (e) => {
    const {name, value } = e.target;

    setformData({...formData, [name]: value});
  } 

  const handleSubmit = () => {
    setLoading(true)

    const contact = {
      _type: 'contact',
      name: name,
      email: email,
      message: message
    }

    client.create(contact)
      .then(() =>{
        setLoading(false);
        setIsFormSubmitted(true);
      })
  }

  return (
    <>
      <h2 className='head-text'> That's it! Let's <span>chat.</span></h2>
      <div className='app__footer-cards'>
          <div className='app__footer-card'>
            <img src={images.email} alt = "email" />
            <a href= "mailto:nahuelmatiasbenitez@gmail.com" className='p-text '>nahuelmatiasbenitez@gmail.com</a>
          </div>
          <div className='app__footer-card'>
            <img src={images.mobile} alt ="mobile" />
            <a href= "tel:+5491156956867" className='p-text '>+5491156956867</a>
          </div>
      </div>
      <div className='img-btns'>
          <a href="https://blog.nbenitez.com.ar"  ><button type="button" className='p-text'>or... check out my blog!</button></a>
        </div>
    {/* {!isFormSubmitted ? 
      <div className='app__footer-form app__flex'>
        <div className='app__flex'>
          <input className='p-text' type="text" placeholder='Your Name' name="name"value={name} onChange={handleChangeInput} />
        </div>
        <div className='app__flex'>
          <input className='p-text' type="email" placeholder='Your Email' name="email"value={email} onChange={handleChangeInput} />
        </div>
        <div>
          <textarea 
            className='p-text'
            placeholder='Your Message'
            value={message}
            name="message"
            onChange={handleChangeInput}
          />
          
        </div>
        <button type="button" className='p-text' onClick={handleSubmit}>{ loading ? 'Sending' : 'Send Message'}</button>
      </div>
    : <div>
        <h3 className='head-text'>Thank you for getting in touch!</h3>
      </div>} */}
    </>
  )
}

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg'
)