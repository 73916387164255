import React, { useState, useEffect } from 'react';
import './BlogPage.scss';
import { urlFor, client } from '../client';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'

const BlogPage = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    // Obtiene los datos de Sanity (blog posts) al cargar la página
    client
      .fetch('*[_type == "links"]') // Reemplaza "post" con el nombre de tu tipo de dato en Sanity
      .then((data) => setBlogPosts(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  return (
      <div className='app__flex'>
        <h1>Links</h1>
        <ul>
          {blogPosts.map((post, index) => (
            <div className='app'>
            <div className='app__links'>
              {/* <img src={urlFor(post.imgUrl)}/> */}
              <li key={post._id} >
                <a href={post.projectLink}>{post.title}</a>
              </li>
              <li key={post._id} className='app__links-emoji' >
              </li>
            </div>
            <h2>{post.emoji}</h2>
            </div>
          ))}
        </ul>
        <li>
          <a href='/'>↩️Go back</a>
        </li>
      </div>
  );
};

export default BlogPage;
