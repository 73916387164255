import React, { useState } from 'react'
import './Navbar.scss'
import { motion } from 'framer-motion' 
import {HiMenuAlt4, HiX } from 'react-icons/hi'

import { images } from '../../constants'

function Navbar() {
  const [toggle, setToggle] = useState(false)
  return (
    <nav className='app__navbar'>
      <div className='app__navbar-logo'>
        <h1>NB</h1>
      </div>
      <ul className="app__navbar-links">
      {['home', 'blog', 'portfolio', 'skills', 'contact'].map((item) => (
  <li className="app__flex p-text" key={`link-${item}`}>
    <div />
    {item === "portfolio" ? (
      <a href="/ben1tz/links">{item}</a>
    ) : item === "blog" ? (
      <a href="https://blog.nbenitez.com.ar">{item}</a>
    ) : (
      <a href={`#${item}`}>{item === "skills" ? item + " & experience" : item}</a>
    )}
  </li>
))}
      </ul>

      <div className='app__navbar-menu'>
  <HiMenuAlt4 onClick={() => setToggle(true)} />
  {toggle && (
    <motion.div
      whileInView={{ x:[300, 0] }}
      transition={{ duration: 0.85, ease: 'easeOut' }}
    >
      <HiX onClick={() => setToggle(false)} />
      <ul className="app__navbar-links">
  {['home', 'blog', 'portfolio', 'skills', 'contact'].map((item) => (
    <li key={item}>
      {item === "portfolio" ? (
        <a href="/ben1tz/links" onClick={() => setToggle(false)}>{item}</a>
      ) : item === "blog" ? (
        <a href="https://blog.nbenitez.com.ar" onClick={() => setToggle(false)}>{item}</a>
      ) : (
        <a href={`#${item}`} onClick={() => setToggle(false)}>{item === "skills" ? item + " & experience" : item}</a>
      )}
    </li>
  ))}
</ul>
    </motion.div>
  )}
</div>

    </nav>
  )
}

export default Navbar