
import './App.scss'
import BlogPage from './components/BlogPage';
import Home from './Home';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { urlFor, client } from './client';

const App = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    // Obtiene los datos de Sanity (blog posts) al cargar la página
    client
      .fetch('*[_type == "works"]') // Reemplaza "post" con el nombre de tu tipo de dato en Sanity
      .then((data) => setBlogPosts(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  return (
    <div className='app'>
      <Router>
        <Routes>
          <Route exact path='/' element={<Home/>} />
          <Route path='/ben1tz/links/' element={<BlogPage/>} />
        </Routes>
      </Router>
    </div>  
  )
}

export default App