import React from 'react'
import { BsTwitter, BsLinkedin, BsGithub } from 'react-icons/bs';

const SocialMedia = () => {
  return (
    <div className='app__social'>
        {/* <div>
            <BsTwitter />
        </div> */}
        <div>
        <a href="https://www.linkedin.com/in/nahuel-benitez-43648b1b7/" target="_blank"><BsLinkedin /></a>
        </div>
        <div>
          <a href="https://github.com/nmbenitez" target="_blank"><BsGithub /></a>
        </div>
    </div>
  )
}

export default SocialMedia